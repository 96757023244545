import React from "react"
import AppContext from "../appcontext"
//import HomeHeader from "../content/home-header"

// import { Link } from "gatsby"

// const imgTest1 = require('../../../static/img/testimonials-1.jpg');
// const imgTest2 = require('../../../static/img/testimonials-2.jpg');
// const imgTest3 = require('../../../static/img/testimonials-3.jpg');
// const imgBg1 = require('../../../static/img/bg-showcase-1.jpg');
// const imgBg2 = require('../../../static/img/bg-showcase-2.jpg');
// const imgBg3 = require('../../../static/img/bg-showcase-3.jpg');

const HomeContent = () => {

	const {placeData, setPlaceData} = React.useContext(AppContext);
	let bannerStyle = {
		backgroundImage: "url(" + placeData.banner + ")"
	};
	console.log("appContext Home",bannerStyle,placeData);
	
return (

  <div id="content">
	{/* Masthead */}

	<header className={"masthead text-white text-center "+placeData.id} style={bannerStyle}>
	  {/* <div className="overlay"></div> */}
	  <div className="container">
	    <div className="row">
	      <div className="col-xl-9 mx-auto">
			
			<h1 className="mb-5">
			{placeData.fullPlaceName}
			<br></br>
			</h1>
			
			<h2 className="mb-5">Augmented Reality Apps for Traveling</h2>
	      </div>
	      {/* <div className="col-md-10 col-lg-8 col-xl-7 mx-auto">
	        <form>
	          <div className="form-row">
	            <div className="col-12 col-md-9 mb-2 mb-md-0">
	              <input type="email" className="form-control form-control-lg" placeholder="Enter your email..." />
	            </div>
	            <div className="col-12 col-md-3">
	              <button type="submit" className="btn btn-block btn-lg btn-primary">Sign up!</button>
	            </div>
	          </div>
	        </form>
	      </div> */}
	    </div>
	  </div>
	</header>

	{/* Icons Grid */}
	<section className="features-icons bg-light text-center">
	  <div className="container">
	    <div className="row">
	      <div className="col-lg-4">
	        <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
	          <div className="features-icons-icon d-flex">
	            <i className="icon-screen-desktop m-auto text-primary"></i>
	          </div>
	          <h3>Exploring</h3>
	          <p className="lead mb-0">the world is a joyful experience that makes us happy and expands our conciousness. </p>
	        </div>
	      </div>
	      <div className="col-lg-4">
	        <div className="features-icons-item mx-auto mb-5 mb-lg-0 mb-lg-3">
	          <div className="features-icons-icon d-flex">
	            <i className="icon-layers m-auto text-primary"></i>
	          </div>
	          <h3>Travel Apps</h3>
	          <p className="lead mb-0">help us discover and learn more about new places with ease and convenience.</p>
	        </div>
	      </div>
	      <div className="col-lg-4">
	        <div className="features-icons-item mx-auto mb-0 mb-lg-3">
	          <div className="features-icons-icon d-flex">
	            <i className="icon-check m-auto text-primary"></i>
	          </div>
	          <h3>Augmented Reality</h3>
	          <p className="lead mb-0">opens up new ways to discover the places of interest we want to explore around us.</p>
	        </div>
	      </div>
	    </div>
	  </div>
	</section>

	{/* Masthead 2 */}
	<header className="solution text-white text-center">
	  <div className="overlay"></div>
	  <div className="container">
	    <div className="row">
	      <div className="col-xl-9 mx-auto">
	        <h1 className="mb-5">Augmented Reality</h1>
			<h2 className="mb-5">AR apps help us find and navigate to places we want to explore in more depth</h2>
	      </div>
	    </div>
	  </div>
	</header>



	{/* Testimonials */}
	{/* <section className="testimonials text-center bg-light">
	  <div className="container">
	    <h2 className="mb-5">What people are saying...</h2>
	    <div className="row">
	      <div className="col-lg-4">
	        <div className="testimonial-item mx-auto mb-5 mb-lg-0">
	          <img className="img-fluid rounded-circle mb-3" src={imgTest1} alt="" />
	          <h5>Margaret E.</h5>
	          <p className="font-weight-light mb-0">"This is fantastic! Thanks so much guys!"</p>
	        </div>
	      </div>
	      <div className="col-lg-4">
	        <div className="testimonial-item mx-auto mb-5 mb-lg-0">
	          <img className="img-fluid rounded-circle mb-3" src={imgTest2} alt="" />
	          <h5>Fred S.</h5>
	          <p className="font-weight-light mb-0">"Bootstrap is amazing. I've been using it to create lots of super nice landing pages."</p>
	        </div>
	      </div>
	      <div className="col-lg-4">
	        <div className="testimonial-item mx-auto mb-5 mb-lg-0">
	          <img className="img-fluid rounded-circle mb-3" src={imgTest3} alt="" />
	          <h5>Sarah W.</h5>
	          <p className="font-weight-light mb-0">"Thanks so much for making these free resources available to us!"</p>
	        </div>
	      </div>
	    </div>
	  </div>
	</section> */}


  </div>

)}

export default HomeContent
