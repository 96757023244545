import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import Nav from "../components/nav"
import HomeContent from "../components/content/home"
import Footer from "../components/footer"
import AppContext from "../components/AppContext"

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <Nav />
    
    <AppContext.Consumer>
    { value =>
    <>      
        {
        value.id === "nyc" ? 
          <HomeContent /> 
          : 
          <HomeContent /> 
        }
    </>
    }
		</AppContext.Consumer>
    
    <Footer />
  </>
)

export default IndexPage
